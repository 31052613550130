
.react-contextmenu {
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 16px;
    color: #373a3c;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    outline: none;
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease !important;
}

    .react-contextmenu.react-contextmenu--visible {
        opacity: 1;
        pointer-events: auto;
    }

.react-contextmenu-item {
    padding: 3px 20px;
    font-weight: 400;
    line-height: 1.5;
    color: #373a3c;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
    cursor: pointer;
}

    .react-contextmenu-item.react-contextmenu-item--active,
    .react-contextmenu-item.react-contextmenu-item--selected {
        color: #fff;
        background-color: #20a0ff;
        border-color: #20a0ff;
        text-decoration: none;
    }

    .react-contextmenu-item.react-contextmenu-item--disabled,
    .react-contextmenu-item.react-contextmenu-item--disabled:hover {
        color: #878a8c;
        background-color: transparent;
        border-color: rgba(0, 0, 0, 0.15);
    }

.react-contextmenu-item--divider {
    margin-bottom: 3px;
    padding: 2px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: inherit;
}

    .react-contextmenu-item--divider:hover {
        background-color: transparent;
        border-color: rgba(0, 0, 0, 0.15);
    }

.react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
}

    .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
    }

        .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
            content: "▶";
            display: inline-block;
            position: absolute;
            right: 7px;
        }


.columns-selector {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    /* width: 200px; */
    height: 80vh;
    overflow-y: scroll;
    background: #fff;
    border: 1px solid;
}